import React, { useEffect } from "react";
import "./App.css";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import YardIcon from "@mui/icons-material/Yard";
import TimerIcon from "@mui/icons-material/Timer";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";

import moment from "moment";

interface Response {
  date: number;
  solenoids: [boolean, boolean, boolean, boolean];
  modifier: number;
  override: boolean;
  lastRun: number;
}

const apiUrl = "http://10.0.0.20";
const endpoints = {
  status: apiUrl + "/",
  on1: apiUrl + "/on/1",
  on2: apiUrl + "/on/2",
  on3: apiUrl + "/on/3",
  off: apiUrl + "/off",
  setOff: apiUrl + "/set/0",
  set20: apiUrl + "/set/20",
  set40: apiUrl + "/set/40",
  set60: apiUrl + "/set/60",
  set80: apiUrl + "/set/80",
  set100: apiUrl + "/set/100",
  override: apiUrl + "/override",
};

function App() {
  const [state, setState] = React.useState<Response>({
    date: 0,
    solenoids: [false, false, false, false],
    modifier: 0,
    override: false,
    lastRun: 0,
  });

  function updateStatus() {
    fetch(endpoints.status)
      .then((res) => res.json())
      .then(
        (result) => {
          setState(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function runCommand(url: string) {
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          setState(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  useEffect(() => {
    updateStatus();
    const timer = setInterval(() => {
      updateStatus();
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <YardIcon style={{ marginRight: 5 }} />
            {state.override ? <TimerOffIcon /> : <TimerIcon />}
            <div style={{ flexGrow: 1 }}></div>
            <Typography>
              {moment.unix(state.date).format("dd DD/MM/yyyy hh:mm a")}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid container spacing={2} style={{ padding: 30 }}>
        <Grid item xs={3} className="solenoid">
          {state.solenoids[0] ? (
            <PlayArrowIcon className="solenoid-icon" />
          ) : (
            <StopIcon className="solenoid-icon" />
          )}
          <br />
          <span>M</span>
        </Grid>
        <Grid item xs={3} className="solenoid">
          {state.solenoids[1] ? (
            <PlayArrowIcon className="solenoid-icon" />
          ) : (
            <StopIcon className="solenoid-icon" />
          )}
          <br />
          <span>1</span>
        </Grid>
        <Grid item xs={3} className="solenoid">
          {state.solenoids[2] ? (
            <PlayArrowIcon className="solenoid-icon" />
          ) : (
            <StopIcon className="solenoid-icon" />
          )}
          <br />
          <span>2</span>
        </Grid>
        <Grid item xs={3} className="solenoid">
          {state.solenoids[3] ? (
            <PlayArrowIcon className="solenoid-icon" />
          ) : (
            <StopIcon className="solenoid-icon" />
          )}
          <br />
          <span>3</span>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          Last run: {moment.unix(state.lastRun).format("dd DD/MM/yyyy hh:mm a")}
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.on1)}
            style={{ width: "100%", height: 60 }}
            disabled={state.solenoids[1]}
          >
            Run 1
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.on2)}
            style={{ width: "100%", height: 60 }}
            disabled={state.solenoids[2]}
          >
            Run 2
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.on3)}
            style={{ width: "100%", height: 60 }}
            disabled={state.solenoids[3]}
          >
            Run 3
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.off)}
            color="error"
            style={{ width: "100%" }}
          >
            Turn Off
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.override)}
            color="warning"
            style={{ width: "100%" }}
            disabled={state.override}
          >
            Skip Tomorrow
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.set100)}
            style={{ width: "100%" }}
            disabled={state.modifier === 100}
          >
            100%
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.set80)}
            style={{ width: "100%" }}
            disabled={state.modifier === 80}
          >
            80%
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.set60)}
            style={{ width: "100%" }}
            disabled={state.modifier === 60}
          >
            60%
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.set60)}
            style={{ width: "100%" }}
            disabled={state.modifier === 40}
          >
            40%
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.set20)}
            style={{ width: "100%" }}
            disabled={state.modifier === 20}
          >
            20%
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => runCommand(endpoints.setOff)}
            style={{ width: "100%" }}
            color="error"
            disabled={state.modifier === 0}
          >
            Off
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
